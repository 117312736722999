/*jslint node: true */
"use strict";
/*globals localStorage,mod, masterUtils, _, angular, window, navigator, $, $sce, ga, gtag, document, ma_ut, fbq, dataLayer, d3, fetch, Image, moment, globalConfig */

angular
    .module('search_filter', ['filter_widget', 'filter_params_store', 'categories_selector'])
    .controller('FilterCtrl',
        ['$sce', '$rootScope', '$scope', 'Bookings', 'FilterParamsStore', 'gettextCatalog', 'loadingService', '$q', 'myConfig', 'alertsService', '$state', '$location', '$window', 'ngDialog',
            function ($sce, $rootScope, $scope, Bookings, FilterParamsStore, gettextCatalog, loadingService, $q, myConfig, alertsService, $state, $location, $window, ngDialog) {

                $scope.filterParamsCtrlPath = $sce.trustAsResourceUrl(myConfig.production ?
                    `${myConfig.middlewareHost}/${myConfig.rootName}/templates/filter_params.html?version=${myConfig.version}` :
                    'templates/filter_params.html');

                $scope.filterResultCtrlPath = $sce.trustAsResourceUrl(myConfig.production ?
                    `${myConfig.middlewareHost}/${myConfig.rootName}/templates/filter_result.html?version=${myConfig.version}` :
                    'templates/filter_result.html');

                const isFromGoogle = $location.search().origin === 'google';

                const DU = masterUtils.dateUtils;

                const experienceModes = {
                    WITHOUT_DATES: 'withoutDates',
                    WITH_CHECK_IN_DATE: 'withCheckInDate',
                    WITH_DATES: 'withDates'
                };

                $window.scrollTo(0, 0);

                Bookings.getAvailability();

                $scope.filterParams = FilterParamsStore.loadFormFilterParams();
                // FilterParamsStore.saveFilterParams($state.params);

                $scope.showToggleModeSelector = false;
                $scope.experiencesEnabled = false;

                $scope.budgets = [];
                $scope.wVGs = [];
                $scope.oWVGs = [];
                $scope.experiences = [];

                $scope.maxAdults = 9;
                $scope.maxChildren = 7;

                $scope.guests = {
                    adults: 0,
                    children: 0,
                    childrenAges: []
                };

                loadingService.enable('filterResults', gettextCatalog.getString('Loading property information and availability'), false, 0);

                $scope.info = {};

                // Debounced search logic.
                $scope.queryPending = true;
                $scope.searching = false;
                $rootScope.showOthers = false;

                $scope.getWvgsLength = function () {
                    return $scope.wVGs.length;
                };

                $scope.getOwvgsLength = function () {
                    return $scope.oWVGs.length;
                };

                $scope.getExperiencesLength = function () {
                    return $scope.experiences.length;
                };

                function setUrlWithNewParams(normalizedFilterParams) {
                    const queryParams = Object.keys(normalizedFilterParams).reduce((acc, key) => {
                        if (normalizedFilterParams[key] != null && normalizedFilterParams[key] != '') {
                            acc[key] = normalizedFilterParams[key];
                        }
                        return acc;
                    }, {});
                    $location.path('/search').search(queryParams).replace();
                }

                $scope.resetResults = function () {
                    $scope.wVGs = [];
                    $scope.oWVGs = [];
                    $scope.experiences = [];
                    $scope.$broadcast("loadElements");
                };


                $scope.doSearch = function () {

                    let wvg; // webVisualizationGroup || productGroups

                    $scope.searching = true;
                    $scope.queryPending = false;
                    $scope.flagButton = false;
                    $rootScope.showOthers = false;

                    $scope.resetResults();

                    if ($scope.filterParams.checkin === null || $scope.filterParams.checkin === undefined) {
                        alertsService.new('searchForm', 'error', gettextCatalog.getString("You can't search without checkin, please fill it."));
                        $scope.searching = false;
                        return;
                    }
                    if ($scope.filterParams.checkout === null || $scope.filterParams.checkout === undefined) {
                        alertsService.new('searchForm', 'error', gettextCatalog.getString("You can't search without checkout, please fill it."));
                        $scope.searching = false;
                        return;
                    }
                    if (!$scope.filterParams.guestAges) {
                        alertsService.new('searchForm', 'error', gettextCatalog.getString("You can't search without child ages, please fill it."));
                        $scope.searching = false;
                        return;
                    }

                    loadingService.enable('principal', gettextCatalog.getString('Searching'), true);

                    const normalizedFilterParams = {
                        checkin: DU.intDate2str($scope.filterParams.checkin),
                        checkout: DU.intDate2str($scope.filterParams.checkout),
                        guestAges: $scope.filterParams.guestAges.join(','),
                        promotionCode: $scope.filterParams.promotionCode != null ? $scope.filterParams.promotionCode.replace(/\s/g, '_') : null,
                        ra: $scope.filterParams.ra,
                        trackref: $scope.filterParams.trackref,
                        guid: $scope.filterParams.guid
                    };
                    const nPeriods = DU.date2int($scope.filterParams.checkout) - DU.date2int($scope.filterParams.checkin);
                    const nPersons = $scope.filterParams.guestAges.length;

                    if ($scope.filterParams.categoryIds.length > 0) {
                        normalizedFilterParams.categoryIds = $scope.filterParams.categoryIds.join(',');
                    }

                    if ($scope.filterParams.facilityIds.length > 0) {
                        normalizedFilterParams.facilityIds = $scope.filterParams.facilityIds.join(',');
                    }

                    normalizedFilterParams.lang = myConfig.lang;

                    $window.scrollTo(0, 0);

                    $scope.searching = true;

                    if (typeof ma_ut !== "undefined") {
                        ma_ut.post({
                            action: "search",
                            message: "Search camping",
                            booking: normalizedFilterParams
                        });
                    }

                    setUrlWithNewParams(normalizedFilterParams);

                    Bookings.getBudgets(normalizedFilterParams)
                        .then(function (response) {

                            const wVGs = [];
                            const oWVGs = [];

                            FilterParamsStore.saveFilterParams(normalizedFilterParams);

                            if (myConfig.trackingId && typeof ga !== "undefined") {
                                ga('send', 'pageview', {page: $location.url()});
                            }

                            if (myConfig.googleGlobalTag && typeof gtag !== "undefined") {
                                gtag('event', 'page_view', {
                                    page_path: $location.url(),
                                    // send_to: myConfig.googleGlobalTag
                                });
                                gtag('event', 'view_search_results');
                            }

                            if (myConfig.facebookPixelCode && typeof fbq !== "undefined") {
                                let search_string = "?";
                                let counter = 0;
                                _.each(_.keys(normalizedFilterParams), function (key) {
                                    if (normalizedFilterParams[key] !== null && normalizedFilterParams[key] !== '' && normalizedFilterParams[key] !== undefined) {
                                        if (counter > 0) {
                                            search_string += '&';
                                        }
                                        search_string += key + '=' + normalizedFilterParams[key];
                                        counter++;
                                    }
                                });
                                fbq('track', 'Search', {search_string});
                            }

                            if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {

                                let searchCounter = window.localStorage.getItem("searchCounter");
                                if (searchCounter == null) {
                                    searchCounter = 1;
                                } else {
                                    searchCounter++;
                                }
                                window.localStorage.setItem('searchCounter', searchCounter);

                                const searchParams = Object.keys(normalizedFilterParams).reduce(function (acc, key) {
                                    if (normalizedFilterParams[key] !== null && normalizedFilterParams[key] !== '' && normalizedFilterParams[key] !== undefined) {
                                        acc[key] = normalizedFilterParams[key];
                                    }
                                    return acc;
                                }, {event: 'search', searchCounter});

                                if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                                    dataLayer.push(searchParams);
                                }

                            }

                            if (!response.budgets) {
                                response.budgets = [];
                            }

                            if (!response.otherProducts) {
                                response.otherProducts = [];
                            }

                            Bookings.getInfo().then(function (infoData) {

                                $scope.info = _.cloneDeep(infoData);

                                if (response.budgets.length === 0) {
                                    if (($scope.info) && ($scope.info.contents) && ($scope.info.contents.messageNoSearchResults)) {
                                        alertsService.new("getBudgets", "warning", $scope.info.contents.messageNoSearchResults);
                                    } else {
                                        alertsService.new("getBudgets", "warning", gettextCatalog.getString("No results found in the selected dates."));
                                    }
                                } else {
                                    for (let i = 0; i < response.budgets.length; i++) {
                                        const budget = response.budgets[i];
                                        const product = angular.copy(_.findWhere($scope.info.products, {idProduct: budget.idProduct}));
                                        if (product == null) {
                                            continue;
                                        }

                                        wvg = _.findWhere(wVGs, {idWebVisualizationGroup: product.idWebVisualizationGroup});
                                        if (wvg == null) {
                                            wvg = angular.copy(_.findWhere($scope.info.webVisualizationGroups, {idWebVisualizationGroup: product.idWebVisualizationGroup}));
                                            if (wvg == null) {
                                                wvg = convertProductToProductGroup(product);
                                            }
                                            wvg.products = [];
                                            wvg.otherProducts = [];
                                            wVGs.push(wvg);
                                        }
                                        wvg.products.push(product);
                                        product.preSelected = true;
                                        product.checkin = budget.checkin.substr(0, 10);
                                        product.checkout = budget.checkout.substr(0, 10);
                                        product.guestAges = normalizedFilterParams.guestAges;
                                        product.promotionTitle = budget.promotionTitle;
                                        product.promotionDescription = budget.promotionDescription;
                                        product.promotionCode = budget.promotionCode != null ? budget.promotionCode.replace(/\s/g, '_') : null;
                                        product.facilityIds = normalizedFilterParams.facilityIds;
                                        product.ra = normalizedFilterParams.ra;
                                        product.trackref = normalizedFilterParams.trackref;
                                        product.guid = normalizedFilterParams.guid;
                                        product.totalPrice = budget.totalPrice;
                                        product.pricePerDay = budget.totalPrice / nPeriods;
                                        product.pricePerPersonPerDay = budget.totalPrice / nPeriods / nPersons;
                                        product.pricePerPerson = budget.totalPrice / nPersons;
                                        product.discount = budget.discount;
                                        product.touristTax = budget.touristTax;
                                        if (isFromGoogle) {
                                            product.totalPrice = product.totalPrice - product.discount;
                                            delete product.extraOptions.template;
                                        }

                                        product.totalWithoutDiscount = product.totalPrice - product.discount;
                                        product.totalWithoutDiscountWithoutTaxes = product.totalWithoutDiscount - product.touristTax;
                                        product.percentageDiscount = Math.round(Math.abs(product.discount) / product.totalWithoutDiscountWithoutTaxes * 100);

                                        if (budget.accommodationAvailable != null) {
                                            const accommodationAvailable = budget.accommodationAvailable;
                                            let notificationMessage = gettextCatalog.getString("#accommodationAvailable accommodations are available");
                                            let color = '#000';
                                            if (product.extraOptions != null) {
                                                if (product.extraOptions.freeAccommodationText != null && product.extraOptions.freeAccommodationText !== "") {
                                                    notificationMessage = product.extraOptions.freeAccommodationText;
                                                }
                                                if (product.extraOptions.freeAccommodationColor != null && product.extraOptions.freeAccommodationColor !== "") {
                                                    color = product.extraOptions.freeAccommodationColor;
                                                }
                                            }
                                            notificationMessage = notificationMessage.replace('#accommodationAvailable', accommodationAvailable);
                                            product.notification = `<span style="color: ${color};">${notificationMessage}</span>`;
                                        }

                                        if (product.extraOptions != null) {
                                            if (product.extraOptions.accommodationMapId != null) {
                                                product.accommodationMapId = product.extraOptions.accommodationMapId;
                                                const accommodationMapOptions = product.extraOptions.accommodationMap;
                                                if (accommodationMapOptions.showAccommodationSelectorButton) {
                                                    product.showAccommodationSelectorButton = true;
                                                    product.accommodationSelectorButtonText =
                                                        accommodationMapOptions.accommodationSelectorButtonText != null && accommodationMapOptions.accommodationSelectorButtonText !== '' ?
                                                            accommodationMapOptions.accommodationSelectorButtonText :
                                                            gettextCatalog.getString("Map");
                                                }
                                                product.priceButtonOpenModal = accommodationMapOptions.priceButtonOpenModal;
                                            }
                                        }

                                    }

                                }

                                if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                                    if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                                        dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                                        const items = [];
                                        for (const wvg of wVGs) {
                                            items.push(...wvg.products.map((product, index) => {
                                                const googleProduct = {
                                                    item_name: product.name,
                                                    item_id: product.idProduct,
                                                    price: product.totalPrice - product.discount,
                                                    item_brand_id: product.idWebVisualizationGroup,
                                                    item_brand: wvg.name,
                                                    index,
                                                    quantity: 1
                                                };
                                                return googleProduct;
                                            }));
                                        }
                                        dataLayer.push({
                                            event: "view_item_list",
                                            ecommerce: {items}
                                        });
                                    }

                                }

                                for (let j = 0; j < response.otherProducts.length; j++) {
                                    const otherProduct = angular.copy(_.findWhere($scope.info.products, {idProduct: parseInt(response.otherProducts[j].idProduct)}));
                                    if (otherProduct == null) {
                                        continue;
                                    }
                                    const budgetData = response.otherProducts[j];
                                    if(budgetData.noAva_totalPrice!=null && budgetData.noAva_totalPrice>0){
                                        otherProduct.noAva_totalPrice = budgetData.noAva_totalPrice;
                                    }
                                    otherProduct.guestAges = normalizedFilterParams.guestAges;
                                    otherProduct.constPromotionCode = normalizedFilterParams.promotionCode != null ? normalizedFilterParams.promotionCode.replace(/\s/g, '_') : null;
                                    otherProduct.ra = normalizedFilterParams.ra;
                                    otherProduct.trackref = normalizedFilterParams.trackref;
                                    otherProduct.guid = normalizedFilterParams.guid;
                                    otherProduct.notAvailable = true;

                                    if (otherProduct.extraOptions != null) {
                                        if (otherProduct.extraOptions.accommodationMapId != null) {
                                            otherProduct.accommodationMapId = otherProduct.extraOptions.accommodationMapId;
                                            const accommodationMapOptions = otherProduct.extraOptions.accommodationMap;
                                            if (accommodationMapOptions.showAccommodationSelectorButton) {
                                                otherProduct.showAccommodationSelectorButton = true;
                                                otherProduct.accommodationSelectorButtonText =
                                                    accommodationMapOptions.accommodationSelectorButtonText != null && accommodationMapOptions.accommodationSelectorButtonText !== '' ?
                                                        accommodationMapOptions.accommodationSelectorButtonText :
                                                        gettextCatalog.getString("Map");
                                            }
                                            otherProduct.priceButtonOpenModal = accommodationMapOptions.priceButtonOpenModal;
                                        }
                                    }

                                    wvg = _.findWhere(wVGs, {idWebVisualizationGroup: otherProduct.idWebVisualizationGroup});
                                    if (wvg) {
                                        wvg.otherProducts.push(otherProduct);
                                        otherProduct.preSelected = false;
                                    } else {
                                        wvg = _.findWhere(oWVGs, {idWebVisualizationGroup: otherProduct.idWebVisualizationGroup});
                                        if (wvg == null) {
                                            wvg = angular.copy(_.findWhere($scope.info.webVisualizationGroups, {idWebVisualizationGroup: otherProduct.idWebVisualizationGroup}));
                                            if (wvg == null) {
                                                wvg = convertProductToProductGroup(otherProduct);
                                            }
                                            wvg.products = [];
                                            oWVGs.push(wvg);
                                        }
                                        wvg.products.push(otherProduct);
                                        oWVGs.preSelected = false;
                                    }
                                }

                                const wvgCounter = _.countBy(response.otherProducts, function (pr) {
                                    return pr.idWebVisualizationGroup;
                                });

                                angular.forEach(wVGs, function (vwgItem, key) {
                                    vwgItem.flagButton = false;
                                    vwgItem.showOthers = false;
                                    if (myConfig.showIfNotAvailable) {
                                        vwgItem.showOthers = true;
                                        return;
                                    }
                                    if (wvgCounter[key]) {
                                        vwgItem.flagButton = true;
                                    }
                                });

                                loadingService.disable('principal');

                                angular.copy(wVGs, $scope.wVGs);

                                if (Object.keys(oWVGs).length > 0) {
                                    $scope.flagButton = true;
                                    if (Object.keys(wVGs).length === 0) {
                                        $rootScope.showOthers = true;
                                    }
                                }
                                angular.copy(oWVGs, $scope.oWVGs);

                                if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                                    if (myConfig.GoogleTagManagerVersion == 2) {
                                        const allWvgs = wVGs.concat(oWVGs);
                                        const allProducts = allWvgs.reduce((acc, wvg) => acc.concat(wvg.products), []);
                                        const items = allProducts.map(function (product, index) {
                                            const gtmProduct = Bookings.processGtmProduct(product, index);
                                            return gtmProduct;
                                        });
                                        dataLayer.push({ecommerce: null, bookingData: null});
                                        dataLayer.push({
                                            event: "view_item_list",
                                            ecommerce: {
                                                items
                                            }
                                        });
                                    }
                                }


                                if (myConfig.showIfNotAvailable) {
                                    $scope.flagButton = false;
                                    $rootScope.showOthers = true;
                                }

                                $scope.$broadcast("loadElements");

                                setTimeout(function () {
                                    $scope.searching = false;
                                }, 1000);

                                scrollAfterSearch();

                                if ($scope.queryPending) {
                                    $scope.doSearch();
                                }

                                loadingService.disable('principal');

                            });

                        }, function (err) {
                            loadingService.disable('principal');
                            $scope.searching = false;
                            if (err == 'Invalid promotion code') {
                                alertsService.new('productNotAvailable', "error", err);
                                $scope.filterParams.promotionCode = null;
                                $scope.queryPending = true;
                            }
                            if (err.includes('vatIncluded=false')) {
                                alertsService.new('getBudgets', "error", err);
                            }
                            if ($scope.queryPending) $scope.doSearch();
                        });
                };

                $scope.showExperiences = function () {

                    $scope.resetResults();

                    let wvg; // webVisualizationGroup || productGroups
                    $scope.searching = true;
                    $scope.queryPending = false;
                    $scope.flagButton = false;
                    $rootScope.showOthers = false;

                    let normalizedFilterParams = {
                        promotionCode: $scope.filterParams.promotionCode != null ? $scope.filterParams.promotionCode.replace(/\s/g, '_') : null,
                        ra: $scope.filterParams.ra,
                        trackref: $scope.filterParams.trackref,
                        guid: $scope.filterParams.guid,
                        experiences: $scope.filterParams.experiences
                    };

                    loadingService.enable('principal', gettextCatalog.getString('Searching'), true);

                    if ($scope.filterParams.categoryIds.length > 0) {
                        normalizedFilterParams.categoryIds = $scope.filterParams.categoryIds.join(',');
                    }

                    normalizedFilterParams.lang = myConfig.lang;

                    $window.scrollTo(0, 0);

                    $scope.searching = true;

                    if (typeof ma_ut !== "undefined") {
                        ma_ut.post({
                            action: "search",
                            message: "Search camping",
                            booking: normalizedFilterParams
                        });
                    }

                    setUrlWithNewParams(normalizedFilterParams);

                    Bookings.getExperiences(normalizedFilterParams)
                        .then(function (response) {

                            const experiences = [];

                            FilterParamsStore.saveFilterParams(normalizedFilterParams);

                            if (myConfig.trackingId && typeof ga !== "undefined") {
                                ga('send', 'pageview', {page: $location.url()});
                            }

                            if (myConfig.googleGlobalTag && typeof gtag !== "undefined") {
                                gtag('event', 'pageview', {page: $location.url()});
                            }

                            if (myConfig.facebookPixelCode && typeof fbq !== "undefined") {
                                let search_string = "?";
                                let counter = 0;
                                _.each(_.keys(normalizedFilterParams), function (key) {
                                    if (normalizedFilterParams[key] !== null && normalizedFilterParams[key] !== '' && normalizedFilterParams[key] !== undefined) {
                                        if (counter > 0) {
                                            search_string += '&';
                                        }
                                        search_string += key + '=' + normalizedFilterParams[key];
                                        counter++;
                                    }
                                });
                                fbq('track', 'Search', {
                                    search_string: search_string,
                                });
                            }

                            if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                                const searchParams = Object.keys(normalizedFilterParams).reduce(function (acc, key) {
                                    if (normalizedFilterParams[key] !== null && normalizedFilterParams[key] !== '' && normalizedFilterParams[key] !== undefined) {
                                        acc[key] = normalizedFilterParams[key];
                                    }
                                    return acc;
                                }, {event: 'search'});
                                if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                                    dataLayer.push(searchParams);
                                }
                            }

                            if (!response.budgets) {
                                response.budgets = [];
                            }

                            if (!response.otherProducts) {
                                response.otherProducts = [];
                            }

                            Bookings.getInfo().then(function (infoData) {

                                $scope.info = _.cloneDeep(infoData);

                                if (response.budgets.length === 0) {
                                    if (($scope.info) && ($scope.info.contents) && ($scope.info.contents.messageNoSearchResults)) {
                                        alertsService.new("getBudgets", "warning", $scope.info.contents.messageNoSearchResults);
                                    } else {
                                        alertsService.new("getBudgets", "warning", gettextCatalog.getString("No results found"));
                                    }

                                } else {
                                    for (let i = 0; i < response.budgets.length; i++) {
                                        const budget = response.budgets[i];
                                        const product = angular.copy(_.findWhere($scope.info.products, {idProduct: budget.idProduct}));
                                        if (product == null) {
                                            continue;
                                        }
                                        wvg = _.findWhere(experiences, {idWebVisualizationGroup: product.idWebVisualizationGroup});
                                        if (wvg == null) {
                                            wvg = angular.copy(_.findWhere($scope.info.webVisualizationGroups, {idWebVisualizationGroup: product.idWebVisualizationGroup}));
                                            if (wvg == null) {
                                                wvg = convertProductToProductGroup(product);
                                            }
                                            wvg.products = [];
                                            wvg.otherProducts = [];
                                            experiences.push(wvg);
                                        }

                                        product.preSelected = false;
                                        product.experience = true;

                                        product.mode = product.extraOptions.mode;
                                        if (product.extraOptions.mode === 'withCheckInDate') {
                                            product.disableCheckOut = true;
                                        }

                                        product.promotionTitle = budget.promotionTitle;
                                        product.promotionDescription = budget.promotionDescription;
                                        product.promotionCode = budget.promotionCode != null ? budget.promotionCode.replace(/\s/g, '_') : null;
                                        product.facilityIds = normalizedFilterParams.facilityIds;
                                        product.ra = normalizedFilterParams.ra;
                                        product.trackref = normalizedFilterParams.trackref;
                                        product.guid = normalizedFilterParams.guid;
                                        product.showBuyButton = function () {
                                            return this.mode === experienceModes.WITHOUT_DATES ||
                                                (this.mode === experienceModes.WITH_CHECK_IN_DATE && this.checkin != null) ||
                                                (this.mode === experienceModes.WITH_DATES && this.checkout != null);
                                        };
                                        wvg.products.push(product);
                                    }

                                    const fGetOrder = (experienceObj)=>{
                                        // const products = experienceObj.products ?? [];
                                        const products = experienceObj.products == null ? [] : experienceObj.products;
                                        if(products.length > 0){
                                         const orders = products.map((product)=>product.order);
                                         return Math.max(...orders);
                                        }else{
                                            return 0;
                                        }
                                    };
                                    experiences.sort((a,b)=>fGetOrder(a) - fGetOrder(b));
                                }

                                loadingService.disable('principal');

                                angular.copy(experiences, $scope.experiences);

                                if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                                    if (myConfig.GoogleTagManagerVersion == 2) {
                                        const allProducts = experiences.reduce((acc, wvg) => acc.concat(wvg.products), []);
                                        const items = allProducts.map(function (product, index) {
                                            const gtmProduct = Bookings.processGtmProduct(product, index);
                                            return gtmProduct;
                                        });
                                        dataLayer.push({ecommerce: null, bookingData: null});
                                        dataLayer.push({
                                            event: "view_item_list",
                                            ecommerce: {
                                                items
                                            }
                                        });
                                    }
                                }

                                $scope.$broadcast("loadElements");

                                setTimeout(function () {
                                    $scope.searching = false;
                                }, 1000);

                                scrollAfterSearch();

                                if ($scope.queryPending) {
                                    $scope.doSearch();
                                }

                                loadingService.disable('principal');

                            });

                        }, function (err) {
                            loadingService.disable('principal');
                            $scope.searching = false;
                            if (err == 'Invalid promotion code') {
                                alertsService.new('productNotAvailable', "error", err);
                                $scope.filterParams.promotionCode = "";
                                $scope.queryPending = true;
                            }
                            if ($scope.queryPending) $scope.doSearch();
                        });
                };

                let mainGroup;

                $scope.selectAccommodation = function (product) {
                    const accommodationMapId = product.extraOptions.accommodationMapId;
                    const selectedFacilityIds = $scope.filterParams.facilityIds || [];
                    const modalWidth = product.extraOptions.accommodationMapModalWidth || '800px';

                    const dialogTemplate = globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/accommodation-map-selector.html?version=${globalConfig.version}` :
                        'templates/accommodation-map-selector.html';

                    ngDialog.open({
                        template: dialogTemplate,
                        showClose: true,
                        width: modalWidth,
                        className: 'ngdialog-theme-default accommodation-map-selector',
                        controller: ["$scope", function ($modalScope) {

                            // $modalScope.availableColor = '#86c974';
                            // $modalScope.notAvailableColor = '#d74242';

                            const checkIn = product.checkin;
                            const checkOut = product.checkout;
                            $modalScope.product = JSON.parse(JSON.stringify(product));
                            $modalScope.product.preSelected = true;
                            if (checkIn instanceof Date) {
                                $modalScope.product.checkin = moment(checkIn).format('YYYY-MM-DD');
                            }
                            if (checkOut instanceof Date) {
                                $modalScope.product.checkout = moment(checkOut).format('YYYY-MM-DD');
                            }

                            const cache = {};

                            $modalScope.loading = true;

                            const accommodationMapRequest = fetch(myConfig.host + '/api/accommodationMap/' + accommodationMapId + '?idProperty=' + myConfig.idProperty).then(response => response.json());
                            let availabilityPath = myConfig.host + '/api/availability?includeFacilities=true&productIds=' + $modalScope.product.idProduct + '&idProperty=' + myConfig.idProperty;
                            if (myConfig.promotionCode != null) {
                                myConfig.promotionCode = myConfig.promotionCode.replace(/\s/g, '_');
                                availabilityPath += '&promotionCode=' + myConfig.promotionCode;
                                $modalScope.product.promotionCode = myConfig.promotionCode;
                                $modalScope.product.constPromotionCode = myConfig.promotionCode;
                            }
                            const availabilityRequest = fetch(availabilityPath).then(response => response.json());

                            Promise.all([accommodationMapRequest, availabilityRequest])
                                .then(([accommodationMap, availability]) => {
                                    availability = availability[$modalScope.product.idProduct];
                                    if (availability == null) {
                                        alertsService.new('noAvailability', 'warning', gettextCatalog.getString('No availability found for this product'));
                                        throw new Error('No availability found for this product');
                                    }
                                    const imageUrl = myConfig.host + '/api/accommodationMap/image/' + accommodationMap.options.image + '?idProperty=' + myConfig.idProperty;
                                    return Promise.all([
                                        accommodationMap,
                                        availability,
                                        loadImage(imageUrl)
                                    ]);
                                })
                                .then(([accommodationMap, availability, image]) => {
                                    cache.accommodationMap = accommodationMap;
                                    cache.availability = availability;
                                    cache.image = image;
                                    $modalScope.loading = false;
                                    $modalScope.availableColor = accommodationMap.options.defaultPointOptions.availableColor;
                                    $modalScope.notAvailableColor = accommodationMap.options.defaultPointOptions.notAvailableColor;
                                    $modalScope.$apply();
                                    loadMap(product, accommodationMap, availability, image);
                                })
                                .catch(err => {
                                    console.error(err);
                                    $modalScope.closeThisDialog();
                                });

                            function loadImage(imageUrl) {
                                return new Promise((resolve, reject) => {
                                    const image = new Image();
                                    image.onload = () => {
                                        resolve(image);
                                    };
                                    image.onerror = (err) => {
                                        reject(err);
                                    };
                                    image.src = imageUrl;
                                });
                            }

                            function loadMap(product, accommodationMap, availability, image) {

                                const productElementId = 'productMap';
                                const productMapElement = document.getElementById(productElementId);

                                productMapElement.innerHTML = "";

                                // const imageUrl = myConfig.host + '/api/accommodationMap/image/' + accommodationMap.options.image;
                                const imageUrl = image.src;
                                let imageWidth = image.width;
                                let imageHeight = image.height;

                                const footerHeight = 50;
                                const headerDistance = 160;

                                if (imageHeight + footerHeight > window.innerHeight - headerDistance) {
                                    $modalScope.product.imageIsBiggerThanWindow = true;
                                }

                                const container = d3.select('#' + productElementId);
                                const svg = container
                                    .html('')
                                    .append('svg')
                                    .attr('width', '100%')
                                    .attr('height', '100%')
                                    .attr('viewBox', '0 0 ' + imageWidth + ' ' + imageHeight)
                                    .style('cursor', 'grab');

                                mainGroup = svg.append('g');

                                mainGroup.append('image').attr('xlink:href', imageUrl);

                                const availableColor = accommodationMap.options.defaultPointOptions.availableColor;
                                const notAvailableColor = accommodationMap.options.defaultPointOptions.notAvailableColor;
                                const outOfProductColor = accommodationMap.options.defaultPointOptions.outOfProductColor;
                                const textColor = accommodationMap.options.defaultPointOptions.textColor;

                                accommodationMap.accommodation_map_points.forEach(point => {
                                    const accommodationAvailability = availability[point.accommodationReference];
                                    let caller = null;
                                    let pointer = false;
                                    point.options.fill = outOfProductColor;
                                    if (accommodationAvailability != null) {
                                        point.options.textColor = textColor;
                                        if (selectedFacilityIds.length === 0 || selectedFacilityIds.every(id => accommodationAvailability.facilities.includes(id))) {
                                            if (checkinIsAvailable(accommodationAvailability, $modalScope.product.checkin)) {
                                                if (checkoutIsAvailable(accommodationAvailability, $modalScope.product.checkin, $modalScope.product.checkout)) {
                                                    point.options.fill = availableColor;
                                                    caller = accommodationSelectedAvailable;
                                                } else {
                                                    point.options.fill = notAvailableColor;
                                                    caller = accommodationSelectedNotAvailable;
                                                }
                                                pointer = true;
                                            } else {
                                                point.options.fill = notAvailableColor;
                                                caller = accommodationSelectedNotAvailable;
                                                pointer = true;
                                            }
                                        }
                                    }
                                    drawCircleWithLabel(point.xPosition, point.yPosition, point.options, point.accommodationReference, product, caller, pointer);
                                });
                                $modalScope.svg = svg;
                                mainGroup = mainGroup;

                                const zoom = d3.zoom().on("zoom", function ({transform}) {
                                    mainGroup.attr("transform", transform);
                                });
                                svg.call(zoom);

                                const buttonBox = container
                                    .append('svg')
                                    .attr('id', 'buttonBox')
                                    .attr('width', '80px')
                                    .attr('height', '30px')
                                    .attr('viewBox', '0 0 80 30')
                                    .style('position', 'absolute')
                                    .style('bottom', '70px')
                                    .style('left', '15px')
                                    .style('z-index', 1000);
                                const zoomIn = buttonBox.append('g')
                                    .attr('class', 'zoom-in')
                                    .style('cursor', 'pointer')
                                    .attr('transform', 'translate(5, 5)')
                                    .on('click', () => {
                                        svg.call(zoom.scaleBy, 1.25);
                                    });
                                zoomIn.append('rect')
                                    .attr('width', 20)
                                    .attr('height', 20)
                                    .attr('rx', 5)
                                    .attr('ry', 5)
                                    .attr('fill', '#fff')
                                    .attr('stroke', '#000')
                                    .attr('stroke-width', 1);
                                zoomIn.append('text')
                                    .attr('x', 10)
                                    .attr('y', 16)
                                    .attr('text-anchor', 'middle')
                                    .attr('font-size', 20)
                                    .attr('font-weight', 'bold')
                                    .attr('fill', '#000')
                                    .text('+');
                                const zoomOut = buttonBox.append('g')
                                    .attr('class', 'zoom-out')
                                    .style('cursor', 'pointer')
                                    .attr('transform', 'translate(30, 5)')
                                    .on('click', () => {
                                        svg.call(zoom.scaleBy, 0.75);
                                    });
                                zoomOut.append('rect')
                                    .style('cursor', 'pointer')
                                    .attr('width', 20)
                                    .attr('height', 20)
                                    .attr('rx', 5)
                                    .attr('ry', 5)
                                    .attr('fill', '#fff')
                                    .attr('stroke', '#000')
                                    .attr('stroke-width', 1);
                                zoomOut.append('text')
                                    .attr('x', 10)
                                    .attr('y', 16)
                                    .attr('text-anchor', 'middle')
                                    .attr('font-size', 20)
                                    .attr('font-weight', 'bold')
                                    .attr('fill', '#000')
                                    .text('-');

                                // Zoom reset with reset icon
                                const zoomReset = buttonBox.append('g')
                                    .attr('class', 'zoom-reset')
                                    .style('cursor', 'pointer')
                                    .attr('transform', 'translate(55, 5)')
                                    .on('click', () => {
                                        svg.call(zoom.transform, d3.zoomIdentity);
                                    });
                                zoomReset.append('rect')
                                    .attr('width', 20)
                                    .attr('height', 20)
                                    .attr('rx', 5)
                                    .attr('ry', 5)
                                    .attr('fill', '#fff')
                                    .attr('stroke', '#000')
                                    .attr('stroke-width', 1);
                                zoomReset.append('text')
                                    .attr('x', 10)
                                    .attr('y', 17)
                                    .attr('text-anchor', 'middle')
                                    .attr('font-size', 20)
                                    .attr('font-weight', 'bold')
                                    .attr('fill', '#000')
                                    .text('↺');
                                /*
                                if (accommodationMap.options.mapTransform != null) {
                                    const mapTransform = accommodationMap.options.mapTransform;
                                    svg.call(
                                        zoom.transform,
                                        d3.zoomIdentity.translate(mapTransform.x, mapTransform.y).scale(mapTransform.k)
                                        // d3.zoomIdentity.translate(0, 0).scale(mapTransform.k)
                                    );
                                }
                                */
                            }

                            function checkinIsAvailable(availability, d) {

                                if (!availability || !availability.matrix) {
                                    return true;
                                }

                                let id;

                                if (d instanceof Date || typeof d === 'string') {
                                    const mD = moment(d);
                                    id = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
                                } else if (typeof d === "number") {
                                    id = d;
                                }
                                const firstBookableDate = moment(availability.firstBookableDate);
                                const firstBookableDateId = Date.UTC(firstBookableDate.year(), firstBookableDate.month(), firstBookableDate.date()) / 86400000;
                                const i = id - firstBookableDateId;
                                if (i < 0) return false;
                                if (i >= availability.matrix.length) return false;
                                return !!availability.matrix[i];
                            }

                            function checkoutIsAvailable(availability, checkinDate, d) {
                                if (!checkinDate) return false;
                                if (!d) return false;
                                const mCin = moment(checkinDate);
                                const iCin = Date.UTC(mCin.year(), mCin.month(), mCin.date()) / 86400000;

                                let iCout;
                                if (d instanceof Date || typeof d === 'string') {
                                    const mD = moment(d);
                                    iCout = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
                                } else if (typeof d === "number") {
                                    iCout = d;
                                }

                                const stayLen = iCout - iCin;
                                if (stayLen <= 0) return false;


                                if ((!availability) || (!availability.matrix)) return true;

                                const firstBookableDate = moment(availability.firstBookableDate);
                                const firstBookableDateId = Date.UTC(firstBookableDate.year(), firstBookableDate.month(), firstBookableDate.date()) / 86400000;

                                const idxIn = iCin - firstBookableDateId;
                                const idxOut = iCout - iCin - 1;

                                if (idxIn < 0) return false;
                                if (idxIn >= availability.matrix.length) return false;
                                if (!availability.matrix[idxIn]) return false;

                                if (idxOut < 0) return false;
                                if (idxOut < 28) {
                                    return !!(availability.matrix[idxIn] & (1 << idxOut));
                                } else {
                                    return false;
                                }

                            }

                            function drawCircleWithLabel(x, y, options, pointId, product, caller, pointerEnabled = false) {
                                const {radius, textSize, textColor, label, fill} = options;
                                const group = mainGroup.append('g')
                                    .attr('class', 'point')
                                    .attr('id', pointId);

                                if (pointerEnabled) {
                                    group.style('cursor', 'pointer');
                                }

                                group.append('circle')
                                    .attr('cx', x)
                                    .attr('cy', y)
                                    .attr('r', radius)
                                    .attr('fill', fill);

                                group.append('text')
                                    .attr('x', x)
                                    .attr('y', y)
                                    .attr('text-anchor', 'middle')
                                    .attr('dominant-baseline', 'middle')
                                    .attr('fill', textColor)
                                    .attr('font-size', textSize)
                                    .text(label);

                                group.on('click', (event) => {
                                    if (caller) {
                                        caller(event, product);
                                    }
                                });
                            }

                            function accommodationSelectedAvailable(event, product) {
                                const pointId = event.currentTarget.id;
                                $modalScope.product.accRef = pointId;
                                const point = cache.accommodationMap.accommodation_map_points.find(p => p.accommodationReference === pointId);
                                $modalScope.product.pointLabel = point.options.label;
                                $modalScope.$apply();
                                if ($modalScope.product.imageIsBiggerThanWindow) {
                                    const element = document.getElementById("makeBookingButton");
                                    element.scrollIntoView({behavior: 'smooth'});
                                }
                                elevatePoint(pointId);
                            }

                            function elevatePoint(pointId) {
                                const point = d3.select(`#${pointId}`);
                                var resaltado = point.classed("point-highlight");
                                if (resaltado) {
                                    point.classed("point-highlight", false);
                                } else {
                                    d3.selectAll(".point").classed("point-highlight", false);
                                    point.classed("point-highlight", true);
                                }
                            }

                            function accommodationSelectedNotAvailable(event, product) {
                                const pointId = event.currentTarget.id;
                                $modalScope.product.accRef = pointId;
                                if (cache.availability[pointId] != null) {
                                    const accommodationAvailability = JSON.parse(JSON.stringify(cache.availability[pointId]));
                                    accommodationAvailability.firstBookableDate = new Date(accommodationAvailability.firstBookableDate);
                                    if (accommodationAvailability.maxStay == null) {
                                        accommodationAvailability.maxStay = 28;
                                    }
                                    $modalScope.product.availability = accommodationAvailability;
                                }
                                $modalScope.product.checkin = null;
                                $modalScope.product.checkout = null;
                                $modalScope.product.preSelected = false;
                                $modalScope.product.notAvailable = true;
                                $modalScope.product.availabilitySearchProcessEnabled = true;
                                const point = cache.accommodationMap.accommodation_map_points.find(p => p.accommodationReference === pointId);
                                $modalScope.product.pointLabel = point.options.label;
                                const messageKey = 'Check accommodation availability {{accommodationReference}}';
                                $modalScope.accommodationNotAvailableMessage =
                                    gettextCatalog.getString(messageKey, {accommodationReference: point.options.label});
                                const mapElement = document.getElementById('productMap');
                                mapElement.remove();
                                $modalScope.$apply();
                            }

                            $modalScope.buy = function () {
                                $modalScope.closeThisDialog();
                                buy($modalScope.product, false);
                            };

                            $modalScope.onDatesChanged = function () {
                                // loadMap(product, cache.accommodationMap, cache.availability, cache.image);
                            };

                        }]
                    });
                };

                function buy(pro, skipOpenModal = true) {

                    if (pro.priceButtonOpenModal && skipOpenModal) {
                        $scope.selectAccommodation(pro);
                        return;
                    }

                    alertsService.reset();

                    const product = angular.copy(pro);

                    if (product.experience !== true && !product.preSelected) {
                        if (product.checkin === null || product.checkin === undefined) {
                            alertsService.new('searchForm', 'error', gettextCatalog.getString("You can't search without checkin, please fill it."));
                            return;
                        }
                        if (product.checkout === null || product.checkout === undefined) {
                            alertsService.new('searchForm', 'error', gettextCatalog.getString("You can't search without checkout, please fill it."));
                            return;
                        }
                    }

                    if (typeof product.checkin === 'number') {
                        product.checkin = new Date(product.checkin * 86400000);
                    }
                    if (product.checkin instanceof Date) {
                        product.checkin = product.checkin.toISOString().substr(0, 10);
                    }

                    if (typeof product.checkout === 'number') {
                        product.checkout = new Date(product.checkout * 86400000);
                    }
                    if (product.checkout instanceof Date) {
                        product.checkout = product.checkout.toISOString().substr(0, 10);
                    }

                    if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {

                        if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                            dataLayer.push({
                                'event': 'productClick',
                                'ecommerce': {
                                    'click': {
                                        'actionField': {'list': 'Search Results'},
                                        'products': [{
                                            'name': product.name,
                                            'id': product.idProduct,
                                            'price': product.totalPrice
                                        }]
                                    }
                                }
                            });
                        }

                        if (myConfig.GoogleTagManagerVersion == 2) {
                            dataLayer.push({ecommerce: null});
                            const gtmProduct = Bookings.processGtmProduct(product);
                            dataLayer.push({
                                event: 'select_item',
                                ecommerce: {items: [gtmProduct]}
                            });
                        }

                    }

                    loadingService.enable('principal', gettextCatalog.getString('Loading'), true, 0);

                    $state.go('bookingForm', product);

                }

                $scope.buy = buy;

                $scope.loading = true;

                Bookings.getInfo()
                    .then(function (infoData) {

                        loadingService.disable('principal');
                        $scope.info = _.cloneDeep(infoData);
                        FilterParamsStore.saveFilterParams($state.params);

                        loadingService.disable('filterResults');
                        let keysUndefined = 0;
                        let flagParams = false;
                        if ($state.params.checkin && $state.params.checkout) {
                            flagParams = true;
                        }
                        angular.forEach($state.params, function (param) {
                            if (param === undefined) {
                                keysUndefined++;
                            }
                        });

                        $scope.showPromotionCodeSection = $scope.info.showPromotionCodeSection;

                        if ($scope.info.published && $scope.info.published.experiences > 0) {
                            $scope.showToggleModeSelector = true;
                            if ($scope.info.published.bookings === 0) {
                                $scope.filterParams.experiences = true;
                                $scope.showToggleModeSelector = false;
                            }
                        }

                        setTimeout(() => {
                            if ($scope.filterParams.experiences) {
                                if ($scope.queryPending) {
                                    $scope.showExperiences();
                                }
                            } else if (keysUndefined !== Object.keys($state.params).length) {
                                if ($scope.queryPending && flagParams) {
                                    $scope.doSearch();
                                }
                            }
                        }, 1000);

                        $scope.loading = false;

                    }, function () {
                        loadingService.disable('filterResults');
                        const translation = gettextCatalog.getString("Oops, something went wrong on our servers. Please try again in a few minutes. We apologize for the inconvenience and appreciate your patience.");
                        alertsService.new('init', "warn", translation);
                    });

            }
        ])
;


function convertProductToProductGroup(product) {
    return {
        idWebVisualizationGroup: `product:${product.idProduct}`,
        name: product.name,
        shortDescription: product.shortDescription,
        longDescription: product.longDescription,
        multimedia: product.multimedia,
        gallery: product.gallery,
        icons: product.icons,
        maps: product.maps,
        plants: product.plants,
        principal: product.principal,
        productInfoDisabled: true,
        product: true
    };
}

function scrollAfterSearch() {
    const elm = document.getElementById("contenedor_productos");
    const stopY = elm.offsetTop;
    const startY = window.scrollY;
    const distance = stopY > startY ? stopY - startY : startY - stopY;
    let speed = Math.round(distance / 10);
    if (speed >= 20) {
        speed = 20;
    }
    const step = Math.round(distance / 25);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    for (let i = startY; i < stopY; i += step) {
        setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
        leapY += step;
        if (leapY > stopY) {
            leapY = stopY;
        }
        timer++;
    }
}
