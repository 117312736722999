// filter widget
"use strict";
/*globals angular, globalConfig */

angular.module('filter_widget', [])
    .directive('filterWidget', ['myConfig', 'gettextCatalog', 'Bookings', '$location',
        function (myConfig, gettextCatalog, Bookings, $location) {
            return {
                // templateUrl: 'templates/filter_widget.html',
                templateUrl : function() {
                    const template = 'templates/filter_widget.html';
                    return globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                        template;
                },
                scope: {
                    selection: '='
                },
                restrict: 'E',
                link: function ($scope, element) {

                    let info;

                    const eCategories = element.children().children()[0];
                    const wCategories = new MasterWidget.CategoriesSelector(eCategories, {
                        title: gettextCatalog.getString("Select categories"),
                        dropdown: false,
                        lang: myConfig.lang
                    });

                    const eDates = element.children().children()[1];
                    const wDates = new MasterWidget.StayDates(eDates, {lang: myConfig.lang});

                    const ePersons = element.children().children()[2];
                    const wPersons = new MasterWidget.Persons(ePersons, {
                        lang: myConfig.lang
                    });

                    const eFacilities = element.children().children()[3];
                    const wFacilities = new MasterWidget.Facilities(eFacilities, {
                        lang: myConfig.lang
                    });

                    if ($scope.selection) {
                        wDates.checkIn.setSelDate($scope.selection.checkin);
                        wDates.checkOut.setSelDate($scope.selection.checkout);
                        wPersons.setAges($scope.selection.guestAges);
                        wFacilities.setSelectedFacilities($scope.selection.facilityIds);
                    }

                    $scope.$watch(()=>$scope.selection.facilityIds, () => {
                        const changeFacilitiesEvent = new CustomEvent('changeFacilities', {detail: $scope.selection.facilityIds});
                        window.dispatchEvent(changeFacilitiesEvent);
                    });

                    function protect(f) {
                        const phase = $scope.$root.$$phase;
                        if (phase == '$apply' || phase == '$digest') {
                            f();
                        } else {
                            $scope.$apply(f);
                        }
                    }

                    wCategories.onChange = function (cats) {
                        protect(function () {

                            const filteredProducts = info.products.filter(function (product) {
                                return product.categories.some(function (categoryId) {
                                    return cats.includes(categoryId);
                                });
                            });
                            const productFacilityIds = filteredProducts.reduce(function (acc, product) {
                                return acc.concat(product.facilities);
                            }, []);

                            const clonedInfo = JSON.parse(JSON.stringify(info));
                            clonedInfo.facilities = clonedInfo.facilities.filter(function (facility) {
                                return productFacilityIds.includes(facility.idFacility.toString());
                            });
                            wFacilities.setInfo(clonedInfo);

                            if (Array.isArray($scope.selection.facilityIds) && $scope.selection.facilityIds.length > 0) {
                                const cleanedFacilityIds = $scope.selection.facilityIds.filter(function (facilityId) {
                                    return productFacilityIds.includes(facilityId);
                                });
                                $scope.selection.facilityIds = cleanedFacilityIds;
                                wFacilities.setSelectedFacilities($scope.selection.facilityIds);
                            }

                            $scope.selection.categoryIds = cats;
                            $scope.updateAvailability();
                        });
                    };

                    wDates.onCheckinDate = function (newDate) {
                        protect(function () {
                            $scope.selection.checkin = newDate;
                            if ($scope.selection.checkout == "Invalid Date") {
                                delete $scope.selection.checkout;
                            }
                            if (!$scope.selection.checkout) {
                                wDates.checkOut.showPopup();
                            }
                            $scope.checkDates();
                        });
                    };

                    wDates.onCheckoutDate = function (newDate) {
                        protect(function () {
                            $scope.selection.checkout = newDate;
                        });
                    };

                    wPersons.onChange = function (newGuestAges) {
                        protect(function () {
                            $scope.selection.guestAges = newGuestAges;
                        });
                    };

                    function facilitiesUpdated(facilities) {
                        protect(function () {
                            $scope.selection.facilityIds = _.map(facilities, function (idFacility) {
                                return idFacility.toString();
                            });
                            $scope.updateAvailability();
                        });
                    }

                    wFacilities.onChange = function (facilities) {
                        facilitiesUpdated(facilities);


                    };

                    $scope.$watch('selection.facilityIds', function (newValue, oldValue) {
                        if (newValue && newValue.toString() !== oldValue.toString()) {
                            facilitiesUpdated(newValue);
                        }
                    });

                    function loadWigdets() {
                        Bookings.getInfo().then(function (newInfo) {

                            info = _.cloneDeep(newInfo);

                            // Normalize categories to string.
                            _.each(info.products, function (p) {
                                p.categories = _.map(p.categories, function (idCategory) {
                                    return idCategory.toString();
                                });
                            });

                            info.facilities = info.facilities.filter(function (facility) {
                                return info.products.some(function (product) {
                                    return product.facilities.includes(facility.idFacility.toString());
                                });
                            });

                            _.each(info.categoryGroups, function (cg) {
                                cg.idCategoryGroup = cg.idCategoryGroup.toString();
                            });

                            _.each(info.categories, function (c) {
                                c.idCategory = c.idCategory.toString();
                                if (c.idCategoryGroup) {
                                    c.idCategoryGroup = c.idCategoryGroup.toString();
                                }
                            });

                            info.products = info.products.filter(function (product) {
                                return product.extraOptions == null || !product.extraOptions.experienceModeEnabled;
                            });

                            const categoryIds = info.products.reduce(function (acc, product) {
                                acc.push(...product.categories);
                                return acc;
                            }, []);

                            info.categories = info.categories.filter(function (category) {
                                return categoryIds.includes(category.idCategory);
                            });

                            let normalCategories = info.categories.map(category => category.idCategory.toString());

                            // Calculate max persons;
                            const maxPersons = info.maxPersons;
                            const maxAdults = info.maxAdults;
                            wPersons.setMaxAdults(maxAdults);
                            wPersons.setMaxPersons(maxPersons);

                            // $scope.selection.categoryIds = $scope.selection.categoryIds.filter((categoryId) => {
                            //     return info.categories.find(category => category.idCategory.toString() === categoryId.toString()) != null;
                            // });

                            if ($scope.selection) {
                                const actualSelection = $scope.selection.categoryIds;
                                let categoriesSelected = actualSelection.filter(idCategory => normalCategories.includes(idCategory.toString()));
                                if (categoriesSelected.length === 0) {
                                    actualSelection.push(...normalCategories);
                                }
                                $scope.selection.categoryIds = actualSelection.reduce((acc, idCategory) => {
                                    if (!acc.includes(idCategory)) {
                                        acc.push(idCategory);
                                    }
                                    return acc;
                                }, []);

                                if (Array.isArray(myConfig.expandedCategoryGroups)) {
                                    wCategories.setCategoryGroups(myConfig.expandedCategoryGroups);
                                }
                            }

                            wCategories.setInfo(info);
                            wFacilities.setInfo(info);

                            $scope.updateAvailability();

                        });
                    }

                    $scope.checkDates = function () {
                        if (!wDates.checkinIsSelectable($scope.selection.checkin)) {
                            $scope.selection.checkin = null;
                            $scope.selection.checkout = null;
                        }
                        if (!wDates.checkoutIsSelectable($scope.selection.checkout)) {
                            $scope.selection.checkout = null;
                        }
                    };

                    $scope.updateAvailability = function () {

                        $scope.selection.facilityIds = _.map($scope.selection.facilityIds, function (idFacility) {
                            return idFacility.toString();
                        });

                        Bookings.getSelectionAvailability({
                            categoryIds: $scope.selection.categoryIds,
                            facilityIds: $scope.selection.facilityIds,
                            productIds: info.products.map(product => product.idProduct.toString())
                        }).then(function (availability) {
                            wDates.setAvailability(availability);
                            setTimeout(function () {
                                $scope.checkDates();
                            }, 1000);
                        });

                    };

                    $scope.$watch('selection', function () {
                        if ($scope.selection) {
                            wDates.checkIn.setSelDate($scope.selection.checkin);
                            wDates.checkOut.setSelDate($scope.selection.checkout);
                            wCategories.setSelectedCategories($scope.selection.categoryIds);
                            wPersons.setAges($scope.selection.guestAges);
                        }
                    }, true);

                    loadWigdets();

                }
            };
        }]);
