/*jslint node: true */
/*global document, angular, google, window, $, _*/
"use strict";

angular
    .module('dynamic_inputs', ['select_country', 'ngDialog'])
    .directive('compareEmail', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.compareEmail = function (modelValue) {
                    const elementId = element.attr('id');
                    const emailId = elementId.replaceAll('Confirmation', '');
                    const input = document.getElementById(emailId);
                    const inputValue = input ? input.value : '';
                    return modelValue == inputValue;
                };

                scope.$watch(()=> {
                    const elementId = element.attr('id');
                    const emailId = elementId.replaceAll('Confirmation', '');
                    const input = document.getElementById(emailId);
                    return input ? input.value : null;
                }, function() {
                    ngModel.$validate();
                });
            }
        };
    })
    .factory('dateUtils', ['gettextCatalog', function (gettextCatalog) {

        const dayRange = [1, 31];
        const months = [
            gettextCatalog.getString('January'),
            gettextCatalog.getString('February'),
            gettextCatalog.getString('March'),
            gettextCatalog.getString('April'),
            gettextCatalog.getString('May'),
            gettextCatalog.getString('June'),
            gettextCatalog.getString('July'),
            gettextCatalog.getString('August'),
            gettextCatalog.getString('September'),
            gettextCatalog.getString('October'),
            gettextCatalog.getString('November'),
            gettextCatalog.getString('December')
        ];

        function changeDate(date) {
            if (date.day > 28) {
                date.day--;
                return date;
            } else if (date.month > 11) {
                date.day = 31;
                date.month--;
                return date;
            }
        }

        return {
            checkDate: function (date) {
                let d;
                if (!date.day || date.month === null || date.month === undefined || !date.year) return false;
                d = new Date(Date.UTC(date.year, date.month, date.day));
                if (d && (d.getUTCMonth() === date.month && d.getUTCDate() === Number(date.day))) {
                    return d;
                }
                return this.checkDate(changeDate(date));
            },
            days: (function () {
                const days = [];
                while (dayRange[0] <= dayRange[1]) {
                    days.push(dayRange[0]++);
                }
                return days;
            }()),
            months: (function () {
                const lst = [],
                    mLen = months.length;

                for (var i = 0; i < mLen; i++) {
                    lst.push({
                        value: i,
                        name: months[i]
                    });
                }
                return lst;
            }())
        };

    }])
    .directive('dynamicInput', ['dateUtils', 'myConfig', 'gettextCatalog', '$state', '$rootScope', '$timeout', 'Bookings',
        function (dateUtils, myConfig, gettextCatalog, $state, $rootScope, $timeout, Bookings) {
            return {
                restrict: 'E',
                // templateUrl: "templates/dynamic_input.html",
                templateUrl: function () {
                    return myConfig.production ?
                        `${myConfig.middlewareHost}/${myConfig.rootName}/templates/dynamic_input.html?version=${myConfig.version}` :
                        'templates/dynamic_input.html';
                },
                scope: {
                    item: '=',
                    key: '=',
                    checkin: '='
                },
                link: function (scope, element, attrs) {

                    scope.loading = true;

                    function setDateFields() {
                        scope.dateFields.day = new Date(scope.item.value[scope.key]).getUTCDate();
                        scope.dateFields.month = new Date(scope.item.value[scope.key]).getUTCMonth();
                        scope.dateFields.year = new Date(scope.item.value[scope.key]).getUTCFullYear();
                    }

                    $timeout(function() {

                        scope.form = scope.$parent[attrs.form];
                        scope.isDocumentComplete = scope.$parent.isDocumentComplete;
                        scope.fileSelected = scope.$parent.fileSelected;
                        scope.imagesUploaded = scope.$parent.imagesUploaded;
                        scope.openDialog = scope.$parent.openDialog;
                        scope.getFormItemValue = scope.$parent.getFormItemValue;


                        scope.uploading = scope.$parent.uploading;
                        scope.checkUploading = scope.$parent.checkUploading;
                        scope.lang = myConfig.lang;
                        scope.setIdCardDirty = scope.$parent.setIdCardDirty;

                        const verifyRequireds = scope.$parent.verifyRequireds;

                        scope.editingStarted = false;

                        scope.isReadOnly = function () {
                            if (scope.item.name === 'lastName' || scope.item.name === 'firstName' || scope.item.name === 'secondLastName') {
                                const value = scope.form["input_" + scope.item.idSection + "_" + scope.item.name + "_" + scope.key].$viewValue;
                                if (scope.item.readonly && (!value || scope.editingStarted)) {
                                    return false;
                                } else {
                                    return scope.item.readonly;
                                }
                            }
                            return scope.item.readonly;
                        };

                        scope.$watch(`item.value[key]`, function(newVal, oldVal) {
                            if (scope.item.name === 'lastName' || scope.item.name === 'firstName' || scope.item.name === 'secondLastName') {
                                if (newVal !== oldVal) {
                                    scope.editingStarted = true;
                                }
                            }
                        });

                        scope.isRequired = function () {
                            if (!verifyRequireds || !scope.item.schema) {
                                return scope.item.required;
                            }
                            if (scope.item.idSection === 1) {
                                const maxRequired = Number.isInteger(scope.item.schema.maxRequired) ? scope.item.schema.maxRequired : 0;
                                return scope.key < maxRequired;
                            }
                            const expeditionInput = scope.form["input_" + scope.item.idSection + "_expeditionDate_" + scope.key];
                            const expirationInput = scope.form["input_" + scope.item.idSection + "_expirationDate_" + scope.key];
                            if ((scope.item.type === 'expeditionDate' || scope.item.type === 'expirationDate') &&
                                (expeditionInput != null && expirationInput != null) &&
                                (expeditionInput.$modelValue || expirationInput.$modelValue)
                            ) {
                                return false;
                            }
                            const guestAge = verifyRequireds[(scope.item.idSection === 0 ? "holder_" : "guest_") + scope.key];
                            return ((guestAge != null && scope.item.schema.minAge) ?
                                guestAge >= scope.item.schema.minAge : scope.item.required);
                        };


                        const maxLengthKeys = {
                            document: 16,
                            address: 32
                        };
                        scope.getMaxLength = function (field) {
                            return maxLengthKeys[field] || null;
                        };

                        scope.showError = function (key) {
                            if (scope.form.$submitted) {
                                if (scope.item.type == 'uploadImage' && !scope.isDocumentComplete(scope.item.idSection, key)) {
                                    return false;
                                }
                                return scope.isInvalid(key);
                            } else {
                                return scope.isInvalid(key) && !scope.isPristine(key);
                            }
                        };

                        scope.getError = function (key) {
                            const formInput = scope.form["input_" + scope.item.idSection + "_" + scope.item.name + "_" + key];
                            if (scope.item.name === 'documentSupport' && formInput.$error.pattern) {
                                return gettextCatalog.getString('must be a valid support code (9 characters)');
                            }
                            if (formInput.$error.compareEmail) {
                                return gettextCatalog.getString('must be the same as the email');
                            }
                            if (formInput.$error.max) {
                                return gettextCatalog.getString('must be greater than 18 years');
                            }
                            if (formInput.$error.maxlength) {
                                return gettextCatalog.getString('must be ' + maxLengthKeys[scope.item.name] + ' characters or less');
                            }
                            if (scope.item.type === 'email' && formInput.$error.pattern) {
                                return gettextCatalog.getString('must be a valid email');
                            }
                            if (scope.item.type === 'document' && formInput.$error.pattern) {
                                return gettextCatalog.getString('must be a valid document');
                            }
                            return gettextCatalog.getString('is required');
                        };

                        scope.isSubmitted = function () {
                            return scope.form.$submitted;
                        };

                        scope.isInvalid = function (key) {
                            const documentInput = scope.form["input_" + scope.item.idSection + "_" + scope.item.name + "_" + key];
                            if (!documentInput) {
                                return false;
                            }
                            if (scope.item.name === 'expeditionDate' || scope.item.name === 'expirationDate') {
                                const expeditionInput = scope.form["input_" + scope.item.idSection + "_expeditionDate_" + key];
                                const expirationInput = scope.form["input_" + scope.item.idSection + "_expirationDate_" + key];
                                if (expeditionInput && expirationInput && expeditionInput.$validators.length && expirationInput.$validators.length) {
                                    return !(expeditionInput.$valid || expirationInput.$valid);
                                }
                            }
                            return documentInput.$invalid;
                        };

                        scope.isPristine = function (key) {
                            if (!scope.form["input_" + scope.item.idSection + "_" + scope.item.name + "_" + key]) {
                                return false;
                            }
                            return scope.form["input_" + scope.item.idSection + "_" + scope.item.name + "_" + key].$pristine;
                        };

                        function calculateAge(date) {
                            const customerAge = _calculateAge(date);
                            scope.$parent.reviewMinAgeRequiredValues(scope.item, customerAge, scope.key);
                        }

                        function _calculateAge(birthday) {
                            if (birthday == null) {
                                return null;
                            }
                            const ageDifMs = Date.now() - birthday.getTime();
                            const ageDate = new Date(ageDifMs);
                            return Math.abs(ageDate.getUTCFullYear() - 1970);
                        }

                        scope._calculateAge = _calculateAge;

                        // BIRTHDAY
                        if (scope.item.type == 'birthdate') {
                            let currentYear = parseInt(attrs.startingYear, 10) || new Date().getFullYear(),
                                numYears = parseInt(attrs.numYears, 10) || 110,
                                oldestYear = currentYear - numYears;

                            scope.years = [];

                            if (scope.item.idSection === 0) {
                                currentYear = currentYear - 17;
                            }

                            for (let i = currentYear; i >= oldestYear; i--) {
                                scope.years.push(i);
                            }

                            scope.days = dateUtils.days;
                            scope.months = dateUtils.months;

                            scope.dateFields = {};

                            scope.dateFields.day = new Date(scope.item.value[scope.key]).getUTCDate();
                            scope.dateFields.month = new Date(scope.item.value[scope.key]).getUTCMonth();
                            scope.dateFields.year = new Date(scope.item.value[scope.key]).getUTCFullYear();


                            // Initialize with current date (if set)
                            scope.$watch('model', function (newDate) {
                                if (newDate) {
                                    scope.dateFields.day = new Date(newDate).getUTCDate();
                                    scope.dateFields.month = new Date(newDate).getUTCMonth();
                                    scope.dateFields.year = new Date(newDate).getUTCFullYear();
                                    calculateAge(newDate);
                                }
                            });

                            scope.checkDate = function () {
                                const date = dateUtils.checkDate(scope.dateFields);
                                if (date) {
                                    // scope.model = date;
                                    scope.item.value[scope.key] = date;
                                    calculateAge(date);
                                }
                            };

                            if (scope.item.idSection === 0 && scope.checkin) {
                                let yearsAgo = new Date(scope.checkin);
                                yearsAgo.setFullYear(yearsAgo.getFullYear() - 18);
                                scope.minDate = new Date(yearsAgo.getTime() + (24 * 60 * 60 * 1000));
                            }

                            scope.showBirthdayInput = true;

                        }
                        // END BIRTHDAY

                        // DATE
                        if (scope.item.type == 'expeditionDate' || scope.item.type == 'expirationDate') {

                            let currentYear2 = parseInt(attrs.startingYear, 10) || new Date().getFullYear(),
                                numYears2 = parseInt(attrs.numYears, 10) || 40;

                            scope.years = [];

                            for (let j = 0; j <= numYears2; j++) {
                                if (scope.item.type == 'expeditionDate') {
                                    scope.years.push(currentYear2 - j);
                                }
                                if (scope.item.type == 'expirationDate') {
                                    scope.years.push(currentYear2 + j);
                                }
                            }

                            scope.days = dateUtils.days;
                            scope.months = dateUtils.months;

                            scope.dateFields = {};

                            setDateFields();

                            // Initialize with current date (if set)
                            scope.$watch('model', function (newDate) {
                                setNewDateFields(newDate);
                            });

                            scope.checkDate = function () {
                                const date = dateUtils.checkDate(scope.dateFields);
                                if (date) {
                                    // scope.model = date;
                                    setNewDateFields(date);
                                    setExpeditionDate();
                                    // scope.form['input_' + scope.item.idSection + '_' + scope.item.type + '_' + scope.key].$setValidity('required', true);
                                    if (scope.form['input_' + scope.item.idSection + '_' + scope.item.type + '_' + scope.key]) {
                                        scope.form['input_' + scope.item.idSection + '_' + scope.item.type + '_' + scope.key].$setDirty(true);
                                    }
                                }
                            };

                            const inputKey = scope.item.idSection + '_' + scope.item.type + '_' + scope.key;
                            window.addEventListener('checkDate_' + inputKey, function () {
                                setDateFields();
                                scope.checkDate();
                            });

                            if (scope.item.type == 'expeditionDate') {
                                scope.$on('setExpirationDate', function ($event, data) {
                                    if (scope.item.idSection === data.idSection && scope.key === data.key) {
                                        $timeout(() => {
                                            if (!scope.form['input_' + scope.item.idSection + '_expeditionDate_' + scope.key].$dirty) {
                                                setNewDateFields(data.newExpeditionDate);
                                            }
                                        });
                                    }
                                });
                            }

                            const setNewDateFields = function (newDate) {
                                // scope.item.value[scope.key] = null;
                                if (newDate) {
                                    const dateInstance = new Date(newDate);
                                    if (scope.years.includes(dateInstance.getUTCFullYear())) {
                                        scope.dateFields.year = dateInstance.getUTCFullYear();
                                        scope.dateFields.month = dateInstance.getUTCMonth();
                                        scope.dateFields.day = dateInstance.getUTCDate();
                                        scope.item.value[scope.key] = newDate;
                                    }
                                }
                            };

                            $timeout(() => {
                                scope.checkDate();
                            });

                        }
                        // END DATE

                        scope.classBoxs = function (item) {

                            if (item.name == 'cvc') {
                                return 'col-md-2 col-sm-2';
                            }
                            if (item.name == 'creditDate') {
                                return 'col-md-3 col-sm-3';
                            }
                            if (item.name == 'ccn') {
                                return 'col-md-7 col-sm-7';
                            }
                            // if (item.type == 'address') {
                            //     return 'col-md-12';
                            // }
                            let css = 'col-md-4 col-sm-4';
                            if (item.schema && item.schema.class) {
                                css = item.schema.class;
                            }
                            return css + ' input-type-' + item.type;
                        };

                        function processPlace(result, putAddress) {
                            var street;
                            var street_number;
                            var piso;
                            var puerta;

                            if (result !== undefined) {
                                if (result.address_components !== undefined && result.address_components.length > 0) {
                                    scope.$apply(function () {
                                        for (let i = 0; i < result.address_components.length; i++) {
                                            const addressType = result.address_components[i].types[0];
                                            const val = result.address_components[i][componentForm[addressType]];
                                            if (addressType == 'postal_code') {
                                                scope.form['input_' + scope.item.idSection + '_zip_' + scope.key].$setViewValue(val);
                                                scope.form['input_' + scope.item.idSection + '_zip_' + scope.key].$render();
                                            } else if (addressType == 'locality') {
                                                scope.form['input_' + scope.item.idSection + '_city_' + scope.key].$setViewValue(val);
                                                scope.form['input_' + scope.item.idSection + '_city_' + scope.key].$render();
                                            } else if (addressType == 'street_number') {
                                                street_number = val;
                                            } else if (addressType == 'route') {
                                                street = val;
                                            } else if (addressType == 'country') {
                                                scope.form['input_' + scope.item.idSection + '_country_' + scope.key].$setViewValue(val);
                                            }
                                        }
                                        if (putAddress) {
                                            scope.form['input_' + scope.item.idSection + '_address_' + scope.key].$setViewValue(scope.item.value[scope.key]);
                                            scope.form['input_' + scope.item.idSection + '_address_' + scope.key].$render();
                                        }
                                        if (street) {
                                            scope.item.value[scope.key] = street + (street_number ? (', ' + street_number) : '');
                                            scope.form['input_' + scope.item.idSection + '_address_' + scope.key].$setViewValue(scope.item.value[scope.key]);
                                            scope.form['input_' + scope.item.idSection + '_address_' + scope.key].$render();
                                        }

                                    });
                                }
                            }
                        }

                        const componentForm = {
                            street_number: 'short_name',
                            route: 'long_name',
                            locality: 'long_name',
                            country: 'short_name',
                            postal_code: 'short_name'
                        };

                        if (scope.item.type == 'address') {

                            /*
                            if (scope.gPlace === undefined) {
                                const e = element[0].querySelector('.autocomplete');
                                scope.gPlace = new google.maps.places.Autocomplete(e, {});
                                var googlePlacesService = new google.maps.places.PlacesService(e);
                                var service = new google.maps.places.AutocompleteService();
                            }
                            */

                            scope.blurAddress = function () {
                                if (!scope.item.value[scope.key] || scope.item.value[scope.key] === '') {
                                    scope.form['input_' + scope.item.idSection + '_zip_' + scope.key].$setViewValue(null);
                                    scope.form['input_' + scope.item.idSection + '_zip_' + scope.key].$render();
                                    scope.form['input_' + scope.item.idSection + '_city_' + scope.key].$setViewValue(null);
                                    scope.form['input_' + scope.item.idSection + '_city_' + scope.key].$render();
                                    scope.form['input_' + scope.item.idSection + '_country_' + scope.key].$setViewValue(null);
                                }
                            };

                            scope.changeAddress = function (e) {
                                if (scope.item.value[scope.key]) {
                                    /*
                                    service.getPlacePredictions({input: scope.item.value[scope.key]}, function (predictions, status) {
                                        if (status != google.maps.places.PlacesServiceStatus.OK) return;
                                        if (predictions[0]) {
                                            googlePlacesService.getDetails({
                                                reference: predictions[0].reference
                                            }, function (details, status) {
                                                if (details) processPlace(details);
                                            });
                                        }
                                    });
                                    */
                                } else {
                                    scope.form['input_' + scope.item.idSection + '_zip_' + scope.key].$setViewValue(null);
                                    scope.form['input_' + scope.item.idSection + '_zip_' + scope.key].$render();
                                    scope.form['input_' + scope.item.idSection + '_city_' + scope.key].$setViewValue(null);
                                    scope.form['input_' + scope.item.idSection + '_city_' + scope.key].$render();
                                    scope.form['input_' + scope.item.idSection + '_country_' + scope.key].$setViewValue(null);
                                }
                            };

                            // google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                            //     processPlace(scope.gPlace.getPlace(), true);
                            // });

                        }

                        if (scope.item.type == 'yearMonth') {
                            scope.yearCards = [];
                            const year = new Date().getFullYear();
                            for (let y = year; y <= year + 10; y++) {
                                scope.yearCards.push({
                                    value: y % 100,
                                    label: y
                                });
                            }
                        }

                        scope.readCarPlate = readCarPlate;

                        function readCarPlate() {
                            if (document.getElementById('item1_0_carPlate') && document.getElementById('item1_0_carPlate').value) {
                                return false;
                            } else {
                                return true;
                            }
                        }

                        scope.selectChanged = function () {
                            if (scope.item.name === 'documentType') {
                                const section = scope.item.idSection === 0 ? 'holder' : 'guest';
                                const documentItem = scope.$parent.bookingForm.form.sections[section].inputs.find(function (item) {
                                    return item.name === 'document';
                                });
                                documentItem.schema.pattern = getPattern(scope);
                            }
                            if (scope.item.name === 'documentType' || scope.item.name === 'country') {
                                setExpeditionDate();
                            }
                        };

                        scope.loading = false;

                    }, 300);

                    function setExpeditionDate() {
                        $timeout(function () {
                            let documentType, expirationDate, birthDate, country;
                            const documentTypeInput = scope.form['input_' + scope.item.idSection + '_documentType_' + scope.key];
                            const expirationDateInput = scope.form['input_' + scope.item.idSection + '_expirationDate_' + scope.key];
                            const birthDateInput = scope.form['input_' + scope.item.idSection + '_birthDate_' + scope.key];
                            const countryInput = scope.form['input_' + scope.item.idSection + '_country_' + scope.key];
                            if (documentTypeInput) {
                                documentType = documentTypeInput.$modelValue;
                            }
                            if (expirationDateInput) {
                                expirationDate = expirationDateInput.$modelValue;
                            }
                            if (birthDateInput) {
                                birthDate = birthDateInput.$modelValue;
                            }
                            if (countryInput) {
                                country = countryInput.$modelValue;
                            }
                            if (country != null && birthDate != null && expirationDate != null) {
                                const newExpeditionDate = Bookings.calculateExpeditionDate(documentType, expirationDate, birthDate, country);
                                $rootScope.$broadcast('setExpirationDate', {
                                    newExpeditionDate,
                                    idSection: scope.item.idSection,
                                    key: scope.key
                                });
                            }
                        });
                    }

                    function getPattern(scope) {
                        const section = scope.item.idSection === 0 ? 'holder' : 'guest';
                        const item = scope.$parent.bookingForm.form.sections[section].inputs.find(function (item) {
                            return item.name === 'document';
                        });
                        const name = item.name;
                        const pattern = item.schema.pattern;
                        const patternEnabled = item.schema.patternEnabled;
                        if (name === 'document' && patternEnabled) {
                            const nifRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
                            const nieRegex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
                            const passportRegex = /^[A-Z]{1}[0-9]{7}$/i;
                            const documentType = scope.form['input_' + scope.item.idSection + '_documentType_' + scope.key].$modelValue;
                            if (documentType != null) {
                                if (documentType == 'ES-ID') {
                                    return nifRegex;
                                }
                                if (documentType == 'ES-X') {
                                    return nieRegex;
                                }
                                if (documentType == 'P') {
                                    // return passportRegex;
                                }
                                return null;
                            }
                        }
                        if (pattern != null && pattern != '') {
                            return pattern;
                        }
                        return null;
                    }

                }
            };
        }]);
